import React, { useEffect, useState } from 'react';
import Highcharts, { ChartOptions } from 'highcharts';
import {requestGraphicScCon, requestConcessionaires, getUserById} from './core/_requests';
import HtmlContent from './components/HtmlContent';
import './styles/styles.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'
import { getUserByToken } from '../../../auth/core/_requests'
import { Alert } from 'react-bootstrap';

const storedObject = JSON.parse(localStorage.getItem('kt-auth-react-v') ?? '{}')
const apiToken = storedObject.api_token;

interface JsonResponse {
    // Define aquí la estructura de tu JSON si la conoces
    [key: string]: any;
}

interface CustomChartOptions extends Highcharts.ChartOptions {
    custom?: {
        label?: Highcharts.SVGElement;
    };
}

interface ResultPercentage {
    name: string;
    y: number;
}

interface Concessionaire {
  codcu: string;
  social_reason: string;
}

const GraphicsComponentScCon = () => {
    const [jsonResponse, setJsonResponse] = useState<JsonResponse>({});
    const [periodo, setPeriodo] = React.useState(new Date().getFullYear());
    const [subPeriodo, setSubPeriodo] = React.useState('01');
    const [concessionaires, setConcessionaires] = useState<Concessionaire[]>([]);
    const [codRed, setCodRed] = React.useState('CU0004');
    const [cantOro, setCantOro] = React.useState(0);
    const [cantPlata, setCantPlata] = React.useState(0);
    const [cantBronce, setCantBronce] = React.useState(0);
    const [cantBasico, setCantBasico] = React.useState(0);
    const [puntos, setPuntos] = React.useState('');
    const [imgPuntos, setImgPuntos] = React.useState('');
    const [zonas, setZonas] = React.useState('');
    const [imgZonas, setImgZonas] = React.useState('');
    const [ventas, setVentas] = React.useState('');
    const [imgVentas, setImgVentas] = React.useState('');
    const [objVentas, setObjVentas] = React.useState('');
    const [imgObjVentas, setImgObjVentas] = React.useState('');
    const [filtros, setFiltros] = React.useState('0');
    const [textoFiltros, setTextoFiltros] = React.useState('');
    const [forceUpdate, setForceUpdate] = useState(0);
    const [userId, setUserId] = useState<number>(0);
    const [profileId, setProfileId] = useState<number>(1);
    const [parcial, setParcial] = useState<boolean>(false);

    const transformData = (jsonData: any[], accountNames: any[]) => {
        // Obtener los nombres únicos de las categorías
        const categories = Array.from(new Set(jsonData.map(item => item.name)));
        
        // Obtener las cuentas únicas, ordenadas por account
        //const accounts = Array.from(new Set(jsonData.map(item => item.account))).sort();
    
        // Transformar los datos al formato deseado
        if(categories!==null && accountNames!==null){
          const transformedData = categories.map(category => {
              return {
                  name: category,
                  data: accountNames.map(account => {
                      // Encontrar el resultado correspondiente para la combinación account + category
                      const found = jsonData.find(item => item.account === account && item.name === category);
                      return found ? found.resultado : 0; // Asignar 0 si no se encuentra el resultado
                  })
              };
          });
          return transformedData;
        }
    };

    const obtenerDatosR0 = (jsonData: any[]) => {

        setCantOro(0);
        setCantPlata(0);
        setCantBronce(0);
        setCantBasico(0);
        setTextoFiltros('');
        setParcial(false);
        
        if (!jsonData || jsonData.length === 0) {
            return;
        }

        //console.log('r0 proceso',jsonData[0]);
        const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

        if(jsonData[0]['parcial']){
            setParcial(true);
            console.log('parcial',true);
        }else{
            console.log('parcial',false);
        }
        
        if (jsonData[0]['categoria_cu_corregido'] === "ORO") {
            setCantOro(1);
        } else if (jsonData[0]['categoria_cu_corregido'] === "PLATA") {
            setCantPlata(1);
        } else if (jsonData[0]['categoria_cu_corregido'] === "BRONCE") {
            setCantBronce(1);
        } else {
            setCantBasico(1);
        }

        // Asignar otros valores
        setPuntos(jsonData[0]['por_puntos']);
        setZonas(jsonData[0]['zonas']);
        setVentas(jsonData[0]['obj_vantas']);
        setObjVentas(jsonData[0]['obj_postventa']);
        //console.log('FFFFIIIILLLLTTTTRRROOOOSSS carga',jsonData[0]['filtros']);
        setFiltros(jsonData[0]['filtros']);

        if(jsonData[0]['filtros'] != '0'){
          console.log('EEEENNNTTTRRRRAAAAA   NNOOO   CCCUUUMMMPPLLLEEE');
          setTextoFiltros('NO CUMPLE');
          sleep(1000).then(() => {
            setForceUpdate(forceUpdate + 1);
          });
        }else{
          console.log('EEEENNNTTTRRRRAAAAA   CCCUUUMMMPPLLLEEE');
          setTextoFiltros('CUMPLE');
          sleep(1000).then(() => {
            setForceUpdate(forceUpdate + 1);
          });
        }

        if(jsonData[0]['por_puntos'] === 'ORO'){
          setImgPuntos('https://api.redtrucksybuses.com.ar/images/logoOro.png');
        }else if(jsonData[0]['por_puntos'] === 'PLATA'){
          setImgPuntos('https://api.redtrucksybuses.com.ar/images/logoPlata.png');
        }else if(jsonData[0]['por_puntos'] === 'BRONCE'){
          setImgPuntos('https://api.redtrucksybuses.com.ar/images/logoBronce.png');
        }else{
          setImgPuntos('https://api.redtrucksybuses.com.ar/images/logoBasico.png');
        }

        if(jsonData[0]['zonas'] === 'ORO'){
          setImgZonas('https://api.redtrucksybuses.com.ar/images/logoOro.png');
        }else if(jsonData[0]['zonas'] === 'PLATA'){
          setImgZonas('https://api.redtrucksybuses.com.ar/images/logoPlata.png');
        }else if(jsonData[0]['zonas'] === 'BRONCE'){
          setImgZonas('https://api.redtrucksybuses.com.ar/images/logoBronce.png');
        }else{
          setImgZonas('https://api.redtrucksybuses.com.ar/images/logoBasico.png');
        }

        if(jsonData[0]['obj_vantas'] === 'ORO'){
          setImgVentas('https://api.redtrucksybuses.com.ar/images/logoOro.png');
        }else if(jsonData[0]['obj_vantas'] === 'PLATA'){
          setImgVentas('https://api.redtrucksybuses.com.ar/images/logoPlata.png');
        }else if(jsonData[0]['obj_vantas'] === 'BRONCE'){
          setImgVentas('https://api.redtrucksybuses.com.ar/images/logoBronce.png');
        }else{
          setImgVentas('https://api.redtrucksybuses.com.ar/images/logoBasico.png');
        }

        if(jsonData[0]['obj_postventa'] === 'ORO'){
          setImgObjVentas('https://api.redtrucksybuses.com.ar/images/logoOro.png');
        }else if(jsonData[0]['obj_postventa'] === 'PLATA'){
          setImgObjVentas('https://api.redtrucksybuses.com.ar/images/logoPlata.png');
        }else if(jsonData[0]['obj_postventa'] === 'BRONCE'){
          setImgObjVentas('https://api.redtrucksybuses.com.ar/images/logoBronce.png');
        }else{
          setImgObjVentas('https://api.redtrucksybuses.com.ar/images/logoBasico.png');
        }
    };

    const desamarR3 = (jsonData: any[]) => {
    
    };

    useEffect(() => {
        if(jsonResponse !== null && Object.keys(jsonResponse).length > 0){
              console.log('previo',jsonResponse);
              const r0 = jsonResponse.r0;
              console.log('r0',r0);
              const r1c = jsonResponse.r1c;
              console.log('r1c',r1c);
              const r1 = jsonResponse.r1;
              console.log('r1',r1);
              const r1vc = jsonResponse.r1vc;
              console.log('r1vc',r1vc);
              const r2 = jsonResponse.r2;
              console.log('r2',r2);
              const r2Total = jsonResponse.r2Total;
              console.log('r2Total',r2Total);
              const r3 = jsonResponse.r3;
              console.log('r3',r3);

              const r3data = r3[4];
              const r3obj = r3[0];
              const r3val = r3[1];
              const r3objac = r3[2];
              const r3valac = r3[3];

              const r4 = jsonResponse.r4;
              console.log('r4',r4);

              const r4data = r4[4];
              const r4obj = r4[0];
              const r4val = r4[1];
              const r4objac = r4[2];
              const r4valac = r4[3];
              
              obtenerDatosR0(r0);

              Highcharts.chart('containerR1', {
                    chart: {
                        type: 'column'
                    },
                    title: {
                        text: '', //'Resultado por Puntos'
                        align: 'center'
                    },
                    //colors: ['#FFD700', '#C0C0C0', '#CD7F32', '#FF0000'], // Paleta de colores para las series
                    xAxis: {
                        categories: r1c
                    },
                    yAxis: {
                        min: 0,
                        title: {
                            text: 'Puntos'
                        }
                        /*,
                        stackLabels: {
                            enabled: true
                        }*/
                    },
                    legend: {
                      shadow: false
                    },
                    tooltip: {
                        shared: true
                    },
                    plotOptions: {
                      column: {
                        grouping: false,
                        shadow: false,
                        borderWidth: 0
                      }
                    },
                    series: [{
                      name: 'Puntos Maximos',
                      color: '#ABABAB',
                      data: r1vc,
                      pointPadding: 0.3,
                      pointPlacement: -0.2
                  }, {
                      name: 'Puntos Cuenta',
                      color: '#474747',
                      data: r1,
                      pointPadding: 0.4,
                      pointPlacement: -0.2
                  }]
                } as Highcharts.Options);


              Highcharts.chart({
                    chart: {
                      renderTo: 'containerR2',
                      type: 'pie',
                      events: {
                        render(this: Highcharts.Chart) {
                          const chart = this;
                          const series = chart.series[0];
                          let customLabel = (chart.options.chart as CustomChartOptions['custom'])?.label;
            
                          if (!customLabel) {
                            customLabel = chart.renderer.label(
                              'Total<br/>' + '<strong>'+r2Total+'</strong>', 0
                            )
                              .css({
                                color: '#000',
                                textAnchor: 'middle',
                              })
                              .add();
                            (chart.options.chart as CustomChartOptions['custom'])!.label = customLabel;
                          }
            
                          const x = series.center[0] + chart.plotLeft;
                          const y = series.center[1] + chart.plotTop; //customLabel.attr('height') / 2
            
                          customLabel.attr({ x, y });
                          customLabel.css({ fontSize: `${series.center[2] / 12}px` });
                        },
                      },
                    } as CustomChartOptions,
                    accessibility: {
                      point: { valueSuffix: '%' },
                    },
                    //colors: ['#FF0000', '#CD7F32', '#C0C0C0', '#FFD700'], // Paleta de colores para las series
                    title: {
                      text: ''//'POR PUNTOS',
                    },
                    subtitle: {
                      text: ''//'Source: <a href="https://www.ssb.no/transport-og-reiseliv/faktaside/bil-og-transport">SSB</a>',
                    },
                    tooltip: {
                      pointFormat: '{series.name}: <b>{point.percentage:.0f}%</b>',
                    },
                    legend: {
                      enabled: true,
                    },
                    plotOptions: {
                      series: {
                        allowPointSelect: true,
                        cursor: 'pointer',
                        dataLabels: [
                          //{
                            //enabled: true,
                            //distance: 20,
                            //format: '{point.name}',
                          //},
                          {
                            enabled: true,
                            //distance: -15,
                            format: '{point.name} - {point.percentage:.0f}%',
                            style: { fontSize: '0.9em' },
                          },
                        ],
                        showInLegend: true,
                      },
                    },
                    series: [
                      {
                        name: '',//'POR PUNTOS',
                        //colorByPoint: true,
                        innerSize: '75%',
                        data: r2
                      },
                    ] as Highcharts.SeriesPieOptions[],
                });

              Highcharts.chart('containerR3', {
                  chart: {
                      type: 'column'
                  },
                  title: {
                      text: '', //'Resultado por Puntos'
                      align: 'center'
                  },
                  //colors: ['#FFD700', '#C0C0C0', '#CD7F32', '#FF0000'], // Paleta de colores para las series
                  xAxis: {
                      categories: r3data
                  },
                  yAxis: [{
                      min: 0,
                      title: {
                          text: 'USD Mensual'
                      }
                    }, {
                      min: 0,
                      title: {
                          text: 'USD Acumulado'
                      },
                      opposite: true
                  }],
                  legend: {
                    shadow: false
                  },
                  tooltip: {
                      shared: true
                  },
                  plotOptions: {
                    column: {
                      grouping: false,
                      shadow: false,
                      borderWidth: 0
                    }
                  },
                  series: [
                  {
                    name: 'Objetivo',
                    color: '#ABABAB',
                    data: r3obj,
                    pointPadding: 0.3,
                    pointPlacement: -0.2,
                    tooltip: {
                        valuePrefix: 'USD '
                    },
                  }, {
                      name: 'Real',
                      color: '#474747',
                      data: r3val,
                      pointPadding: 0.4,
                      pointPlacement: -0.2,
                      tooltip: {
                          valuePrefix: 'USD '
                      },
                  },
                  {
                    name: 'Objetivo Acumulado',
                    color: '#AFC7DB',
                    data: r3objac,
                    pointPadding: 0.3,
                    pointPlacement: 0.2,
                    yAxis: 1,
                    tooltip: {
                        valuePrefix: 'USD '
                    },
                  }, {
                      name: 'Real Acumulado',
                      color: '#3A8FDB',
                      data: r3valac,
                      pointPadding: 0.4,
                      pointPlacement: 0.2,
                      yAxis: 1,
                      tooltip: {
                          valuePrefix: 'USD '
                      },
                  }]
              } as Highcharts.Options);

              Highcharts.chart('containerR4', {
                  chart: {
                      type: 'column'
                  },
                  title: {
                      text: '', //'Resultado por Puntos'
                      align: 'center'
                  },
                  //colors: ['#FFD700', '#C0C0C0', '#CD7F32', '#FF0000'], // Paleta de colores para las series
                  xAxis: {
                      categories: r3data
                  },
                  yAxis: [{
                    min: 0,
                    title: {
                        text: 'Unidades Mensual'
                    }
                }, {
                    title: {
                        text: 'Unidades Acumulado'
                    },
                    opposite: true
                }],
                  legend: {
                    shadow: false
                  },
                  tooltip: {
                      shared: true
                  },
                  plotOptions: {
                    column: {
                      grouping: false,
                      shadow: false,
                      borderWidth: 0
                    }
                  },
                  series: [
                  {
                    name: 'Objetivo',
                    color: '#ABABAB',
                    data: r4obj,
                    pointPadding: 0.3,
                    pointPlacement: -0.2
                  }, {
                      name: 'Real',
                      color: '#474747',
                      data: r4val,
                      pointPadding: 0.4,
                      pointPlacement: -0.2
                  },
                  {
                    name: 'Objetivo Acumulado',
                    color: '#AFC7DB',
                    data: r4objac,
                    pointPadding: 0.3,
                    pointPlacement: 0.2,
                    yAxis: 1
                  }, {
                      name: 'Real Acumulado',
                      color: '#3A8FDB',
                      data: r4valac,
                      pointPadding: 0.4,
                      pointPlacement: 0.2,
                      yAxis: 1
                  }]
              } as Highcharts.Options);

      }

  }, [jsonResponse]);

    useEffect(()=>{
        async function ejecutarFuncionesEnOrden() {
          await devolverUsuario();
          //await fetchConcessionaires();
        }

        ejecutarFuncionesEnOrden();
        // devolverUsuario();
        // fetchConcessionaires();
    },[]);

    useEffect(()=>{
      async function ejecutarFuncionesEnOrden() {
        await fetchConcessionaires();
      }

      ejecutarFuncionesEnOrden();
      // devolverUsuario();
      // fetchConcessionaires();
    },[userId]);

    const devolverUsuario = async () => {
        const userDataToken = await getUserByToken(apiToken);
        console.log('userDataToken',userDataToken);

        if(userDataToken.data !== null){
            setUserId(userDataToken.data.id);
            const codCuenta = userDataToken.data.codCuenta;

            if(codCuenta !== null) {
                const codCuentaS = codCuenta?.toString();
                if (codCuentaS) {
                    setCodRed(codCuentaS.toString());
                    console.log('codCuenta',codCuentaS);
                }
            }

            const profId = userDataToken.data.profile_id;

            if(profId !== null) {
                const profIdInt = profId;
                if (profIdInt) {
                    setProfileId(profIdInt);
                    console.log('profileId',profIdInt);
                }
            }
        }
    };

    const funcionDevolverDatos = async () => {
        try {
            console.log(codRed);
            const periodoT = periodo+subPeriodo;
            const cuenta = codRed;
            const response = await requestGraphicScCon(cuenta,periodoT,userId);

            if (response.data.success === true) {
                const tbody = response.data.data;
                console.log('response data',tbody);
                setJsonResponse(tbody);
            }else{
                console.log('Error:', response.data.error);  
                window.alert(response.data.error);
            }
        
        }catch (error) {
          console.log('Error:', error);
        }
    }
    
    const fetchConcessionaires = async () => {
        try {
            const response = await requestConcessionaires(userId);
            if (response.data.success === true) {
              const conc = response.data.data;
              console.log('response data',conc);
              setConcessionaires(conc);
            }
        } catch (error) {
            console.error('Error fetching concessionaires:', error);
        }
    }; 

  return (
    <div>
        <div className='form-container' style={{ backgroundColor: 'black', height: '70px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <label className='col-form-label fw-bold' style={{ color: 'white', marginRight: '10px', fontSize: '1.4em' }}>
              CONCESIONARIO -
            </label>
            <div style={{marginRight: '10px'}} >
                  <select 
                    value={codRed} 
                    className='custom-datepicker fw-bold' 
                    style={{height: '70%', marginRight: '10px', width: '400px'}} 
                    onChange={(e) => setCodRed(e.target.value)}
                    disabled={profileId === 2}
                  >
                    {concessionaires.map(concessionaire => (
                        <option key={concessionaire.codcu} value={concessionaire.codcu}>
                            {concessionaire.social_reason}
                        </option>
                    ))}
                </select>
            </div>
            <label className='col-form-label fw-bold' style={{ color: 'white', marginRight: '10px', fontSize: '1.4em' }}>
              ETAPA -
            </label>
            <div style={{marginRight: '10px'}} >
              <DatePicker
                selected={new Date((periodo + 1).toString())}
                onChange={(date: Date) => setPeriodo(date.getFullYear())}
                dateFormat='yyyy'
                showYearPicker
                className='fw-bold custom-datepicker'
              />
            </div>
            <select value={subPeriodo} className='custom-datepicker fw-bold' style={{height: '70%', marginRight: '10px'}} onChange={(e) => setSubPeriodo(e.target.value)}>
              <option value="01">01</option>
              <option value="02">02</option>
            </select>
            <button className='custom-file-upload btn btn-primary' style={{marginTop:'20px'}} onClick={funcionDevolverDatos}>
              CARGAR
            </button>
        </div>
        
          
          <div>
              <div style={{ margin: '30px 0 0 0'  }}>
                
                <div style={{ display: 'flex', width: '100%' }}>
                  <div id='containerImg1' style={{ textAlign: 'center', width: '30%', margin: '0 auto', marginRight: '5px', padding: '0 0 10px 0' }}>
                    <div className='form-container' style={{ backgroundColor: 'black', width: '100%', height: '70px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <label className='col-form-label fw-bold' style={{ color: 'white', marginRight: '10px', fontSize: '1.4em' }}>
                        RESULTADO
                      </label>
                    </div>
                  </div>

                  <div id='containerImg2' style={{ textAlign: 'center', width: '70%', margin: '0 auto', marginLeft: '5px', padding: '0 0 10px 0' }}>
                    <div className='form-container' style={{ backgroundColor: 'black', width: '100%', height: '70px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <label className='col-form-label fw-bold' style={{ color: 'white', marginRight: '10px', fontSize: '1.4em' }}>
                        RESUMEN
                      </label>
                    </div>
                  </div>
                </div>              

                <div style={{ display: 'flex', width: '100%' }}>
                {(parcial === false) ? (
                  <>
                    {cantOro !== 0 ? (
                      <>
                        <div id='containerImg1' style={{ backgroundColor: '#F7F7F7',textAlign: 'center', width: '30%', margin: '0 auto', marginRight: '10px'}}>
                          <img src="https://api.redtrucksybuses.com.ar/images/logoEtapaOro.png" alt="Logo Etapa Oro" style={{ height: '250px', margin:'5px'}} />
                          <p className='col-form-label fw-bold' style={{ textAlign: 'center', fontSize: '2em', color: '#EABE3F' }}>ORO</p>
                        </div>
                        
                      </>
                    ) : null}

                    {cantPlata !== 0 ? (
                      <>
                        <div id='containerImg1' style={{ backgroundColor: '#F7F7F7',textAlign: 'center', width: '30%', margin: '0 auto', marginRight: '10px'}}>
                          <img src="https://api.redtrucksybuses.com.ar/images/logoEtapaPlata.png" alt="Logo Etapa Plata" style={{ height: '250px', margin:'5px'}} />
                          <p className='col-form-label fw-bold' style={{ textAlign: 'center', fontSize: '2em', color: '#C0C0C0' }}>PLATA</p>
                        </div>
                        
                      </>
                    ) : null}

                    {cantBronce !== 0 ? (
                      <>
                        <div id='containerImg1' style={{ backgroundColor: '#F7F7F7',textAlign: 'center', width: '30%', margin: '0 auto', marginRight: '10px'}}>
                          <img src="https://api.redtrucksybuses.com.ar/images/logoEtapaBronce.png" alt="Logo Etapa Bronce" style={{ height: '250px', margin:'5px'}} />
                          <p className='col-form-label fw-bold' style={{ textAlign: 'center', fontSize: '2em', color: '#CD7F32' }}>BRONCE</p>
                        </div>
                        
                      </>
                    ) : null}

                    {cantBasico !== 0 ? (
                      <>
                        <div id='containerImg1' style={{ backgroundColor: '#F7F7F7',textAlign: 'center', width: '30%', margin: '0 auto', marginRight: '10px'}}>
                          {/* <img src="https://api.redtrucksybuses.com.ar/images/logoEtapaBasico.png" alt="Logo Etapa Basico" style={{ height: '250px', margin:'5px'}} /> */}
                          <p className='col-form-label fw-bold' style={{ textAlign: 'center', fontSize: '2em', color: '#FF0000' }}>BASICO</p>
                        </div>
                        
                      </>
                    ) : null}
                  </>
                ) : 
                  <div id='containerImg1' style={{ backgroundColor: '#F7F7F7',textAlign: 'center', width: '30%', margin: '0 auto', marginRight: '10px'}}></div>
                } 
                
                <div id='containerImg2' style={{ textAlign: 'center', width: '35%', margin: '0 auto', display: 'flex',flexDirection: 'column' }}>
                    <div className='box' style={{ backgroundColor: '#F7F7F7', width: '100%', height: '90px', display: 'flex', alignItems: 'center', padding: '5px' }}>
                      <img src={imgPuntos} style={{ height: '80px', margin: '0 20px 0 50px' }} />
                      <p className='col-form-label fw-bold' style={{ textAlign: 'center', fontSize: '1.4em', color: '#000000', margin: '0' }}>PUNTOS</p>
                    </div>  
                    <div className='box' style={{ backgroundColor: '#F7F7F7', width: '100%', height: '90px', display: 'flex', alignItems: 'center', padding: '5px' }}>
                      <img src={imgZonas} style={{ height: '80px', margin: '0 20px 0 50px' }} />
                      <p className='col-form-label fw-bold' style={{ textAlign: 'center', fontSize: '1.4em', color: '#000000', margin: '0' }}>ZONAS</p>
                    </div>  
                    <div className='box' style={{ backgroundColor: '#F7F7F7', width: '100%', height: '90px', display: 'flex', alignItems: 'center', padding: '5px' }}>
                      <p className='col-form-label fw-bold' style={{ textAlign: 'center', fontSize: '1.4em', color: '#000000', margin: '0 0 0 50px' }}>Filtros: { textoFiltros }</p>
                    </div>  
                </div>

                <div id='containerImg3' style={{ textAlign: 'center', width: '35%', margin: '0 auto', display: 'flex', flexDirection: 'column' }}>
                <div className='box' style={{ backgroundColor: '#F7F7F7', width: '100%', height: '90px', display: 'flex', alignItems: 'center', padding: '5px' }}>
                      <img src={imgVentas} style={{ height: '80px', margin: '0 20px 0 50px' }} />
                      <p className='col-form-label fw-bold' style={{ textAlign: 'center', fontSize: '1.4em', color: '#000000', margin: '0' }}>OBJETIVO VENTAS</p>
                    </div>  
                    <div className='box' style={{ backgroundColor: '#F7F7F7', width: '100%', height: '90px', display: 'flex', alignItems: 'center', padding: '5px' }}>
                      <img src={imgObjVentas}style={{ height: '80px', margin: '0 20px 0 50px' }} />
                      <p className='col-form-label fw-bold' style={{ textAlign: 'center', fontSize: '1.4em', color: '#000000', margin: '0' }}>OBJETIVO POSVENTA</p>
                    </div>  
                    <div className='box' style={{ backgroundColor: '#F7F7F7', width: '100%', height: '90px' }}></div>
                </div> 

              </div>
            </div>
        </div>
        

        <div className='form-container' style={{ backgroundColor: 'black', height: '70px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <label className='col-form-label fw-bold' style={{ color: 'white', marginRight: '10px', fontSize: '1.4em' }}>
              RESULTADO POR PUNTOS
            </label>
        </div>
        <br />
        <br />
        <div id='containerR1'></div>
        <br />
        <br />
        <div className='form-container' style={{ backgroundColor: 'black', height: '70px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <label className='col-form-label fw-bold' style={{ color: 'white', marginRight: '10px', fontSize: '1.4em' }}>
              RESULTADO POR FILTROS
            </label>
        </div>
        <br />
        <br />
        <div id='containerR2'></div>
        <br />
        <br />
        <div className='form-container' style={{ backgroundColor: 'black', height: '70px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <label className='col-form-label fw-bold' style={{ color: 'white', marginRight: '10px', fontSize: '1.4em' }}>
              RESULTADO OBJETIVO POSVENTA
            </label>
        </div>
        <br />
        <br />
        <div id='containerR3'></div>
        <br />
        <br />
        <div className='form-container' style={{ backgroundColor: 'black', height: '70px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <label className='col-form-label fw-bold' style={{ color: 'white', marginRight: '10px', fontSize: '1.4em' }}>
              RESULTADO OBJETIVO VENTA
            </label>
        </div>
        <br />
        <br />
        <div id='containerR4'></div>

    </div>
    
  );
};

export default GraphicsComponentScCon;